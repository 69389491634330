.homepage {
    height: 100%;
  }

  .homepageContainer {
      padding: 5%;
  }

  h2 {
    font-size:36px
  }

h3 {
    text-align: center;
}

.open-p {
    width: auto;
}

.services-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: left center right;
    justify-content: space-evenly;
    grid-gap: 5%;
    width: 80%;
}

.left-item, .right-item{
    background-color:lightgray;
    padding: 1em;
    margin-top: -.5em;
    margin-bottom: -.5em;
}

.homepage-Icon {
    height: 50%;
}

.card-intro {
    padding-top: 3%;
    display: flex;
    width: 100%;
}

@media screen and (max-width: 700px){
    .open-p {
        width: 80%;
    }
    
   .services-container {
       display: block;
   }

   .left-item {
       background-color: #D3C0D2;
   }
}

@media screen and (max-width: 400px) {
    a.homepage-link{
        font-size: 1rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
}