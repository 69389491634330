 .parallax {
    /* The image used */
    background-image: url('../../images/fallMumsbanner.png');
    filter: blur(2px);
    -webkit-filter: blur(2px);
    opacity: .8;
    width: 100%;
  
    /* Full height */
    height: 250px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

  }

  .inner-section {
    text-align: center;
    margin: auto;
    background-image: none;
    
}

.bigText {
    background-color: black; /*fallback color*/
    background-color: rgba(0,0,0, 0.6); /*black with opacity*/
    color: white;
    font-weight: bold;
    border: 3px solid white;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 80%;
    padding: 20px;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
}

.intro-p {
    margin-bottom: 2%;
}

.homepage-link {
    padding: 1%;
    background-color: #5B7553;
    color: white;
    text-decoration: none;
    font-size: 200%;
    margin: 10%;
    box-shadow: #E4FDE1 2px;
    border: #32472c 2px solid;
    margin-top: 3%;
    font-family: 'Lexend Giga', sans-serif;
    
}

.homepage-link:hover {
    background-color: #E4FDE1;
    color: black;
    box-shadow: none;
}

@media screen and (max-width: 1074px){
   .bigText {
       margin-top: 10%
   }
}

@media screen and (max-width: 900px){
    .bigText {
        margin-top: 20%
    }
 }

 @media screen and (max-width: 580px){
    .bigText {
        margin-top: 50%
    }
 }