.webTW {
    text-align: center;
    margin: 2rem;
}

.blogPostsContainer {
    margin: 1rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.blogContainer >p {
    text-align: center;
    margin-bottom: 2rem;
}

.lighthouseTestimg {
    margin: 2rem; 
}

.lighthouseTestimg:hover {
    width: 450px;
}

.side-space {
    background-color: aquamarine;
}

.blogPostsContainer h2 {
    margin-top: 2rem;
    text-align: center;
}

.blog-option {
    color: blue;
    text-decoration: wavy;
    margin-left: 2rem;
}
.thumgnail-img {
    text-align: left;
    float: left;
}
.blog-container {
    border-bottom: 1.5px solid darkgreen;
    padding-bottom: 2rem;
   }