footer {
    background-color: #D3C0D2;
    padding: 2em;
    display: flex;
    justify-content: space-evenly;
}

.footer-menu {
    justify-content: flex-end;
}

.footer-option {
    color: black;
    text-align:right;
    text-decoration: none;
}

.footer-option:hover {
    font-size: 2rem;
}